<template>
    <section>
        <div class="row mx-0 py-2 align-items-center">
            <div class="btn-back" @click="irAtras">
                <i class="icon-left f-14" />
            </div>
            <div class="col text-general f-15">
                Detalle del cupón
            </div>
            <div class="col-auto d-middle">
                <span class="text-general mx-2">
                    Cupón {{ cupon.estado ? 'Activo' : 'Inactivo' }}
                </span>
                <el-switch
                v-show="!en_cedis"
                v-model="cupon.estado"
                :active-value="1"
                :inactive-value="0"
                :disabled="!$can('botones_cupon_gestionar')"
                active-color="#000000"
                @change="cambiarEstadoCupon"
                />
            </div>
            <div v-if="$can('botones_cupon_gestionar') && !en_cedis" class="btn-action border">
                <el-tooltip placement="bottom" content="Mover de carpeta" effect="light">
                    <i class="icon-folder-move-outline f-20 cr-pointer" @click="moverCarpeta()" />
                </el-tooltip>
            </div>
            <div v-if="$can('botones_cupon_gestionar') && !en_cedis" class="btn-action border ml-2">
                <el-tooltip placement="bottom" content="Duplicar cupónes" effect="light">
                    <i class="icon-content-duplicate f-20 cr-pointer" @click="duplicarCupon()" />
                </el-tooltip>
            </div>
            <div v-if="$can('botones_cupon_gestionar') && !en_cedis" class="btn-action border ml-2">
                <el-tooltip placement="bottom" content="Editar cupón" effect="light">
                    <i class="icon-pencil-outline f-22 cr-pointer" @click="irEditar" />
                </el-tooltip>
            </div>
        </div>
        <tabs :tabs="rutasTabs" class="border-bottom" />
        <div class="custom-scroll overflow-auto" :style="$route.name == 'admin.cedis.cupones.ver.informacion1' || $route.name == 'admin.cedis.cupones.ver.clientes-especificos' || $route.name == 'admin.cedis.cupones.ver.informacion2' ? 'height:calc(100vh - 260px);' : 'height:calc(100vh - 170px);'">
            <router-view />
        </div>
        <!-- Partials -->
        <modal-mover-carpeta ref="modalMoverCarpeta" />
        <modal-duplicar-cupones ref="modalDuplicarCupones" />
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalMoverCarpeta: () => import('../partials/modalMoverCarpeta'),
        modalDuplicarCupones: () => import('../partials/modalDuplicarCupones')
    },
    data(){
        return {
            cuponActivo: false,
        }
    },

    computed:{
        ...mapGetters({
            cupon: 'cupones/cupones/datosDetalle'
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
        volver(){
            return this.$route.name == 'admin.cedis.cupones.ver.informacion1' ? '"admin.cedis.cupones.lista"' : 'admin.cupones.lista'
        },
        en_cedis(){
            if(this.$route.params.id_cedis){
                return true;
            }
            return false
        },
        rutasTabs(){
            let rutasCupones = []

            if(this.cupon.destinatario === 1){
                rutasCupones.push({
                    titulo: `${this.$config.cliente} específicos`,
                    ruta: 'admin.cupones.ver.clientes-especificos'
                })
            }
            if(this.cupon.destinatario === 2){
                rutasCupones.push({
                    titulo: `${this.$config.vendedor} específicos`,
                    ruta: 'admin.cupones.ver.clientes-especificos'
                })
            }

            if(this.cupon.tipo === 1){
                rutasCupones.unshift({
                    titulo: 'Información',
                    ruta: 'admin.cupones.ver.informacion1'
                },)
            }
            if(this.cupon.tipo === 2){
                rutasCupones.unshift({
                    titulo: 'Información',
                    ruta: 'admin.cupones.ver.informacion2'
                },)
            }

            let rutasCedis = []

            if(this.cupon.destinatario === 1){
                rutasCedis.push({
                    titulo: `${this.$config.cliente} específicos`,
                    ruta: 'admin.cedis.cupones.ver.clientes-especificos'
                })
            }
            if(this.cupon.destinatario === 2){
                rutasCedis.push({
                    titulo: `${this.$config.vendedor} específicos`,
                    ruta: 'admin.cedis.cupones.ver.clientes-especificos'
                })
            }

            if(this.cupon.tipo === 1){
                rutasCedis.unshift({
                    titulo: 'Información',
                    ruta: 'admin.cedis.cupones.ver.informacion1'
                },)
            }
            if(this.cupon.tipo === 2){
                rutasCedis.unshift({
                    titulo: 'Información',
                    ruta: 'admin.cedis.cupones.ver.informacion2'
                },)
            }

            if(this.en_cedis){
                return rutasCedis;
            }

            return rutasCupones;
        }
    },
    created(){
        this.getDetalle()
    },
    methods: {
        async getDetalle(){
            try {
                this.$store.dispatch('cupones/cupones/getDetalleCupon',this.id_cupon)

            } catch (e){
                this.error_catch(e)
            }
        },
        moverCarpeta(){
            this.$refs.modalMoverCarpeta.toggle();
        },
        duplicarCupon(){
            this.$refs.modalDuplicarCupones.toggle();
        },
        async cambiarEstadoCupon(){
            try {

                const {data} = await Cupones.cambiarEstado(this.id_cupon)

            } catch (e){
                this.error_catch(e)
            }
        },
        irEditar(){
            this.$router.push({name:'admin.cupones.editar',params:{id_cupon:this.id_cupon}})
        },
        irAtras(){
            if(this.cupon.destinatario === 1){
                this.$router.push({name: 'admin.cupones.clientes.lista'})
                return
            }
            if(this.cupon.destinatario === 2){
                this.$router.push({name: 'admin.cupones.lechero.lista'})
                return
            }
        }
    }
}
</script>
<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
</style>
